import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from '@env/_current/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.productionMode) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
