import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/_current/environment';
import { User } from '@models/index';

@Injectable()
export class UserService {
  constructor(private _http: HttpClient) {}
  /* CRUD and User endpoint Calls */
  /* Read User by id*/
  // TODO: how do I get a user by id?
  /* Update User by id */
  getById(id: number) {
    return this._http.get(`${environment.apiUrl}/users/${id}`);
  }

  update(user: User) {
    return this._http.put(`${environment.apiUrl}/users/${user.id}`, user);
  }

  delete(id: number) {
    return this._http.delete(`${environment.apiUrl}/users/${id}`);
  }
}
