import { APP_PROVIDERS } from './app.providers';
import { BrowserModule } from '@angular/platform-browser';
import { FeaturesComponent } from '@features/features.component';
import { FeaturesModule } from '@features/features.module';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';

@NgModule({
  declarations: [],
  imports: [FeaturesModule, BrowserModule, HttpClientModule, StoreModule.forRoot({}, {}), NgbModule],
  providers: [...APP_PROVIDERS],
  bootstrap: [FeaturesComponent]
})
export class AppModule {}
