import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './alert/alert.component';
import { CommonModule } from '@angular/common';
import { LogInFormComponent } from './log-in-form/log-in-form.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { NgModule } from '@angular/core';
import { PageContainerComponent } from './page-container/page-container.component';
import { RouterModule } from '@angular/router';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AlertComponent,
    NavFooterComponent,
    NavHeaderComponent,
    SearchBarComponent,
    LogInFormComponent,
    PageContainerComponent
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [
    AlertComponent,
    // CheckInModalComponent,
    NavFooterComponent,
    NavHeaderComponent,
    PageContainerComponent,
    SearchBarComponent,
    LogInFormComponent
  ]
})
export class ComponentsModule {}
