import { SERVICES, SentryErrorHandler } from '@services/index';
import { ErrorHandler } from '@angular/core';
import { INTERCEPTORS } from '@interceptors/index';
import { REMOTES } from '@remotes/index';

export const APP_PROVIDERS = [
  // ...CONSTANTS,
  ...INTERCEPTORS,
  ...REMOTES,
  ...SERVICES,
  // GoogleMapsAPIWrapper,
  { provide: ErrorHandler, userClass: SentryErrorHandler }
];
