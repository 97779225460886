<!-- Footer -->
<footer>
  <!-- Footer Links -->
  <div class="container text-center text-md-left">
    <!-- Grid row -->
    <div class="row">
      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 mr-auto my-md-4 my-0 mt-4 mb-1">
        <!-- Content -->
        <h5 class="font-weight-bold text-uppercase mb-4">Footer Content</h5>
        <p>Here you can use rows and columns here to organize your footer content.</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit amet numquam iure provident voluptate esse
          quasi, veritatis totam voluptas nostrum.
        </p>
      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none" />

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 mx-auto my-md-4 my-0 mt-4 mb-1">
        <!-- Links -->
        <h5 class="font-weight-bold text-uppercase mb-4">About</h5>

        <ul class="list-unstyled">
          <li>
            <p>
              <a href="#!">PROJECTS</a>
            </p>
          </li>
          <li>
            <p>
              <a href="#!">ABOUT US</a>
            </p>
          </li>
          <li>
            <p>
              <a href="#!">BLOG</a>
            </p>
          </li>
          <li>
            <p>
              <a href="#!">AWARDS</a>
            </p>
          </li>
        </ul>
      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none" />

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 mx-auto my-md-4 my-0 mt-4 mb-1">
        <!-- Contact details -->
        <h5 class="font-weight-bold text-uppercase mb-4">Address</h5>

        <ul class="list-unstyled">
          <li>
            <p><i class="fa fa-home mr-3"></i> New York, NY 10012, US</p>
          </li>
          <li>
            <p><i class="fa fa-envelope mr-3"></i> info@example.com</p>
          </li>
          <li>
            <p><i class="fa fa-phone mr-3"></i> + 01 234 567 88</p>
          </li>
          <li>
            <p><i class="fa fa-print mr-3"></i> + 01 234 567 89</p>
          </li>
        </ul>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    © 2018 Copyright:
    <p>Code-Slingers Inc.</p>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
