import { Routes } from '@angular/router';
export const FeaturesRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./static-pages/static-pages.module').then((m) => m.StaticPagesModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule)
  },
  {
    path: 'dogParks',
    loadChildren: () => import('./dog-parks/dog-parks.module').then((m) => m.DogParksModule)
  },
  {
    path: 'accountSettings',
    loadChildren: () => import('./account-settings/account-settings.module').then((m) => m.AccountSettingsModule)
  },
  { path: '**', redirectTo: '/not-found' }
];
