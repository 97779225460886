import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/index';
import { FEATURE_ROUTES } from '@constants/features-routes';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html'
})
export class NavHeaderComponent {
  readonly ABOUT_ROUTE = FEATURE_ROUTES.About;
  readonly CONTACT_ROUTE = FEATURE_ROUTES.Contact;
  readonly DOG_PARKS_ROUTE = FEATURE_ROUTES.DogParks;
  readonly HOME_ROUTE = FEATURE_ROUTES.Home;
  readonly LOG_IN_ROUTE = FEATURE_ROUTES.LogIn;
  readonly MAP_ROUTE = FEATURE_ROUTES.Map;
  readonly REG_ROUTE = FEATURE_ROUTES.Register;
  readonly SETTINGS_ROUTE = FEATURE_ROUTES.Settings;

  // readonly MAP_ROUTE = ROUTE_PATHS.;

  isLoggedIn$: Observable<boolean>;

  constructor(private authService: AuthService, private router: Router) {}

  onLogout() {
    // this.authService.logout();
    this.router.navigate(['/static-pages/']);
  }
}
