import { User } from '@models/user';
import { createReducer, on } from '@ngrx/store';
import { AuthActions, AuthApiActions } from '../actions';

export const authFeatureKey = 'auth';

export interface State {
  errorMessage: string | null;
  token: string | null;
  user: User | null;
}

export const initialState: State = {
  errorMessage: null,
  token: null,
  user: null
};

export const reducer = createReducer(
  initialState,

  on(AuthApiActions.loginSuccess, (state, { token }) => ({
    ...state,
    token: token
  })),

  on(AuthActions.logout, () => initialState)
);

export const getCurrentUser = (state: State) => state.user;
export const getErrorMessage = (state: State) => state.errorMessage;
export const getToken = (state: State) => state.token;
