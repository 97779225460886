import { createAction, props } from '@ngrx/store';

export const LOG_OUT = '[Auth] Log Out';

// NOTE: move sign up into own file for actions.
export const SIGNUP = '[Auth] Sign Up';
export const SIGNUP_FAIL = '[Auth] Sign Up Failed';
export const SIGNUP_SUCCESS = '[Auth] Sign Up Success';

export const logout = createAction(LOG_OUT);

export const signup = createAction(SIGNUP, props<{ payload: any }>());
export const signupFailure = createAction(SIGNUP_FAIL, props<{ errMsg: string }>());
export const signupSuccess = createAction(SIGNUP_SUCCESS, props<{ message: string }>());
