import { AuthService } from './auth.service';
import { CheckInService } from './check-in.service';
import { DogParkService } from './dog-park.service';
import { UserService } from './user.service';

export const REMOTES = [AuthService, CheckInService, DogParkService, UserService];

export * from './auth.service';
export * from './check-in.service';
export * from './dog-park.service';
export * from './user.service';
