import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/_current/environment';
import { Auth, AuthResponse, LoginCredentials } from '@models/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  constructor(private _http: HttpClient) {}

  login({ email, password }: LoginCredentials): Observable<Auth> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(`${email}:${password}`)}`
    });

    return this._http.post(`${environment.apiUrl}/auth/login`, {}, { headers: headers }).pipe(
      map(({ jwtToken }: AuthResponse): Auth => {
        // this.setJwtToken(response.jwtToken);
        return {
          email,
          password,
          token: jwtToken
        };
      })
    );
  }

  signup(
    email: string,
    password: string,
    zipcode: string,
    dob: string,
    firstName?: string,
    lastName?: string,
    username?: string
  ): Observable<any> {
    return this._http.post(
      `${environment.apiUrl}/auth/signup`,
      { email, password, zipcode, dob, firstName, lastName, username },
      {}
    );
  }

  // getTokenExpirationDate(token: string): Date {
  //   const decoded: any = jwt_decode(token);

  //   if (decoded.date === undefined) {
  //     return null;
  //   }

  //   const date = new Date(0);
  //   date.setUTCSeconds(decoded.exp);
  //   return date;
  // }

  // isTokenExpired(token?: string): boolean {
  //   if (!token) {
  //     token = this.jwtToken();
  //   }

  //   if (!token) {
  //     return true;
  //   }
  //   const date = this.getTokenExpirationDate(token);
  //   if (date === undefined) {
  //     return false;
  //   }
  //   return !(date.valueOf() > new Date().valueOf());
  // }

  // logout(): void {
  //   localStorage.removeItem(WAGGLEZ_TOKEN);
  // }
}
