import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/_current/environment';
import { DogPark } from '@models/index';
import { Observable } from 'rxjs';
@Injectable()
export class DogParkService {
  // data$: Observable<HttpResponse<DogPark>> = this.dataSubject.asObservable();

  // private listOfDogParks = new Observable<DogPark>();

  // NOTES: I'm thinking about the archiecture of the dogPark Service and the other services.
  constructor(private _http: HttpClient) {}

  getById(uuid: string): Observable<DogPark> {
    // UUID
    return this._http.get<DogPark>(`${environment.apiUrl}/dogParks/${uuid}`);
  }

  // Create a dog park, but really the user submits it for review first.
  create(dogPark: DogPark) {
    return this._http.post(`${environment.apiUrl}/dogParks`, dogPark);
  }

  // // Just get me a list of dog parks, with a valid jwt.
  getDogParks(): Observable<DogPark[]> {
    // here you would format the request
    // requires valid login user.
    return this._http.get<DogPark[]>(`${environment.apiUrl}/dogParks`);
  }

  getDogParksNearBy(lng: string, lat: string, threshold: string): Observable<DogPark[]> {
    // need to pass some kind of query params, back
    // long and lat.
    // radial search return.
    const data = {
      lng: lng,
      lat: lat,
      threshold: threshold
    };
    // NOTE: This could be apart of the jwt.
    return this._http.get<DogPark[]>(`${environment.apiUrl}/dogParks/gis`, {
      params: data
    });
  }
}
