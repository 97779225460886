import { Injectable } from '@angular/core';

@Injectable()
export class ApiEndpointService {
  /**
   * Map of Protocols for API Endpoints
   */
  public static PROTOCOL = {
    HTTP: 'http://',
    HTTPS: 'https://'
  };

  /**
   * Map of contexts for API endpoints.
   */
  public static CONTEXT = 'api/';

  public static AUTH_CONTEXT = 'auth/';

  /**
   * Determines if the requested URL is an Authentication API endpoint.
   * @param {string} url
   * @returns {boolean}
   */
  public static isAuthEndpoint(url: string = ''): boolean {
    return url.toLowerCase().indexOf(this.AUTH_CONTEXT) > -1;
  }

  /**
   * Determines if the requested URL is an API endpoint.
   * @param {string} url
   * @returns {boolean}
   */
  public static isApiEndpoint(url: string = ''): boolean {
    return url.toLowerCase().indexOf(this.CONTEXT) > -1;
  }
}
