import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <app-nav-header></app-nav-header>
    <app-alert></app-alert>
    <router-outlet></router-outlet>
    <app-nav-footer class="mt-auto"></app-nav-footer>
  `
})
export class FeaturesComponent {
  constructor() {}
}
