export const FEATURE_ROUTES = {
  About: 'about',
  ForgotPassword: 'auth/forgot-password',
  Home: 'home',
  Contact: 'contact',
  DogParks: 'dog-parks',
  Map: 'dog-parks/map',
  Settings: 'user/settings',
  Invite: 'auth/invite',
  LogIn: 'auth/login',
  NotFound: 'not-found',
  Register: 'auth/register',
  ResetPassword: 'auth/reset-password',
  SignUpSuccess: 'auth/sign-up-success'
};
