import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import { environment, environmentType } from '@env/index';

if (environment.serverEnviroment !== environmentType.develop) {
  Sentry.init({
    dsn: 'https://55d70c50ddac4c63aac8a8313542fc35@o406887.ingest.sentry.io/5275207',
    integrations: [new RewriteFrames()]
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any) {
    if (environment.serverEnviroment !== environmentType.develop) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }
}
