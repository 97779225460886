import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/_current/environment';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}

  /**
   * Map of contexts for API endpoints.
   */

  readonly AUTH_CONTEXT = 'auth/';

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers;

    const isAuthEndpoint: boolean = this._isAuthEndpoint(request.url);

    headers = this._addJsonContentTypeHeader(headers);
    headers = this._addJsonReturnTypeHeader(headers);

    if (request.method === 'POST') {
      headers = headers.set('observe', 'response');
    }

    if (isAuthEndpoint) {
      headers = this._addApiToken(headers);
    } else {
      headers = this._addBearerToken(headers);
    }

    request = request.clone({
      headers: headers
    });

    return next.handle(request);
  }

  private _addBearerToken(headers: HttpHeaders): HttpHeaders {
    const token = {};
    return token ? headers.set('Authorization', `Bearer ${token}`) : headers;
  }

  private _addApiToken(headers: HttpHeaders): HttpHeaders {
    return headers.append('Authorization', `Bearer ${environment.apiToken}`);
  }

  /**
   * Determines if the requested URL is an Authentication API endpoint.
   * @param {string} url
   * @returns {boolean}
   */
  private _isAuthEndpoint(url: string = ''): boolean {
    return url.toLowerCase().indexOf(this.AUTH_CONTEXT) > -1;
  }

  private _addJsonContentTypeHeader(headers: HttpHeaders) {
    return headers.set('Content-Type', 'application/json');
  }

  private _addJsonReturnTypeHeader(headers: HttpHeaders) {
    return headers.set('Accept', 'application/json');
  }
}
