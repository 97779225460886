import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/_current/environment';
import { User } from '@models/user';
import { Observable, bindNodeCallback } from 'rxjs';

@Injectable()
export class AuthService {
  private _authFlag = 'isLoggedIn';
  private _expiresAt: number;
  private _logoutUrl = 'http://localhost:4200';
  private _onAuthFailureUrl = '/login';
  private _onAuthSuccessUrl = '/home';
  private WAGGLEZ_TOKEN = 'wagglez-jwt';

  constructor(private _http: HttpClient) {}

  get authSuccessUrl(): string {
    return this._onAuthSuccessUrl;
  }

  get authFailureUrl(): string {
    return this._onAuthFailureUrl;
  }

  get authenticated(): boolean {
    return JSON.parse(localStorage.getItem(this._authFlag));
  }

  register(user: User) {
    return this._http.post(`${environment.apiUrl}/auth/signup`, user);
  }

  resetAuthFlag() {
    localStorage.removeItem(this._authFlag);
  }

  login(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(`${email}:${password}`)}`
    });
    return this._http.post<any>(`${environment.apiUrl}/auth/login`, {}, { headers: headers });
  }

  setAuth(authResult) {
    this._expiresAt = authResult.expiresIn * 1000 + Date.now();
    // Set flag in local storage stating this app is logged in
    localStorage.setItem(this._authFlag, JSON.stringify(true));
  }

  logout() {
    // Set authentication status flag in local storage to false
    localStorage.setItem(this._authFlag, JSON.stringify(false));
    // This does a refresh and redirects back to homepage
    // Make sure you have the logout URL in your Auth0
    // Dashboard Application settings in Allowed Logout URLs
  }

  private setJwtToken(token: string): void {
    // TODO: Could do some validation here maybe.
    localStorage.setItem(this.WAGGLEZ_TOKEN, token);
  }

  // clearSession(): void {
  //   this._sessionService.destroy();
  // }

  // confirmToken(firstName: string, lastName: string, password: string, token: string): Observable<any> {
  //   return this._http.post(this._sessionService.getRootEndpointUrl('/token/confirm'), {
  //     firstName: firstName,
  //     lastName: lastName,
  //     password: password,
  //     token: token,
  //   });
  // }

  // forgotPassword(email: string): Observable<any> {
  //   return this._http.post(this._sessionService.getRootEndpointUrl('/password/reset'), { email: email });
  // }

  // login(email: string, password: string): Observable<any> {
  //   const data = { email: email, password: password };
  //   return this._http.post(this._sessionService.getRootEndpointUrl('/login'), data);
  // }

  // logout(router?: Router, error?: any): void {
  //   this.clearSession();

  //   if (router) {
  //     const path = ROUTE_AUTHENTICATION_PATHS.LOGIN;

  //     if (error) {
  //       router.navigate([path], {
  //         replaceUrl: true,
  //         queryParams: { error: '1' },
  //       });
  //     } else {
  //       router.navigate([path], { replaceUrl: true });
  //     }
  //   }
  // }
}
