import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../root-reducers';
import * as fromAuth from '../reducers/auth.reducer';
import * as fromLoginPage from '../reducers/login-page.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
  [fromAuth.authFeatureKey]: fromAuth.State;
  [fromLoginPage.loginPageFeatureKey]: fromLoginPage.State;
}

export interface State extends fromRoot.State {
  [authFeatureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [fromAuth.authFeatureKey]: fromAuth.reducer,
    [fromLoginPage.loginPageFeatureKey]: fromLoginPage.reducer
  })(state, action);
}

export const selectAuthFeatureState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectAuthState = createSelector(selectAuthFeatureState, (state: AuthState) => state.auth);

export const selectToken = createSelector(selectAuthState, fromAuth.getToken);

export const selectCurrentUser = createSelector(selectAuthState, fromAuth.getCurrentUser);

export const selectAuthErrorMessage = createSelector(selectAuthState, fromAuth.getErrorMessage);

// export const selectIsAuthenticated = createSelector(selectAuthState, fromAuth.getIsAuthenticated);

export const selectLoginPageState = createSelector(selectAuthFeatureState, (state: AuthState) => state.loginPage);

export const selectLoginPageError = createSelector(selectLoginPageState, fromLoginPage.getError);

export const selectLoginPagePending = createSelector(selectLoginPageState, fromLoginPage.getPending);
