<nav class="navbar navbar-expand-md navbar-fixed-top navbar-dark bg-dark main-nav">
  <div class="container">
    <ul class="nav navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" routerLink="/">
          {{ 'nav.home' | translate | titlecase }}
        </a>
      </li>

      <li class="nav-item" *ngIf="isLoggedIn$ | async">
        <a class="nav-link" routerLink="{{ MAP_ROUTE }}" routerLinkActive="active">
          {{ 'nav.map' | translate | titlecase }}
        </a>
      </li>

      <li class="nav-item" *ngIf="isLoggedIn$ | async">
        <a class="nav-link" routerLink="{{ DOG_PARKS_ROUTE }}" routerLinkActive="active">
          {{ 'nav.dogParks' | translate | titlecase }}
        </a>
      </li>

      <li class="nav-item" *ngIf="(isLoggedIn$ | async) === null || (isLoggedIn$ | async) === undefined">
        <a class="nav-link" routerLink="{{ REG_ROUTE }}" routerLinkActive="active">
          {{ 'nav.signup' | translate | titlecase }}
        </a>
      </li>
    </ul>

    <ul class="nav navbar-nav mx-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="{{ HOME_ROUTE }}">
          {{ 'nav.title' | translate | titlecase }}
        </a>
      </li>
    </ul>

    <ul class="nav navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="{{ ABOUT_ROUTE }}">
          {{ 'nav.about' | translate | titlecase }}
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="{{ CONTACT_ROUTE }}">
          {{ 'nav.contact' | translate | titlecase }}
        </a>
      </li>

      <li class="nav-item" *ngIf="isLoggedIn$ | async">
        <a class="nav-link" routerLink="{{ SETTINGS_ROUTE }}">
          {{ 'nav.settings' | translate | titlecase }}
        </a>
      </li>

      <li class="nav-item" *ngIf="(isLoggedIn$ | async) === null || (isLoggedIn$ | async) === undefined">
        <a class="nav-link" routerLink="{{ LOG_IN_ROUTE }}" routerLinkActive="active">
          {{ 'nav.login' | translate | titlecase }}
        </a>
      </li>

      <li class="nav-item" *ngIf="isLoggedIn$ | async">
        <button type="button" class="btn btn-primary" (click)="onLogout()">
          {{ 'nav.logout' | translate | titlecase }}
        </button>
      </li>
    </ul>
  </div>
</nav>
