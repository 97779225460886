import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/_current/environment';
import { CheckIn } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class CheckInService {
  constructor(private _http: HttpClient) {}

  // Required to be 10 ft from checkin SPot
  checkIn(checkIn: CheckIn) {
    return this._http.post(`${environment.apiUrl}/checkIn`, checkIn);
  }
}
