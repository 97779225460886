import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@models/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@services/auth.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { FEATURE_ROUTES } from '@constants/features-routes';
import { AuthActions, AuthApiActions, LoginPageActions } from '../actions';

@Injectable()
export class AuthEffects {
  constructor(private _actions$: Actions, private _authService: AuthService, private _router: Router) {}

  login$ = createEffect((): any =>
    this._actions$.pipe(
      ofType(LoginPageActions.login),
      mergeMap((action) =>
        this._authService.login(action.credentials).pipe(
          map(({ token }: Auth) => AuthApiActions.loginSuccess({ token })),
          catchError(({ message }) => of(AuthApiActions.loginFailure({ errMsg: message })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthApiActions.loginSuccess),
        tap(() => this._router.navigateByUrl(FEATURE_ROUTES.Map))
      ),
    { dispatch: false }
  );

  loginRedirect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthApiActions.loginRedirect, AuthActions.logout),
        tap(() => {
          this._router.navigateByUrl(FEATURE_ROUTES.Home);
        })
      ),
    { dispatch: false }
  );

  // signup$ = createEffect((): any =>
  //   this._actions$.pipe(
  //     ofType(AuthActions.signup),
  //     map((action) => action.payload),
  //     switchMap((payload) => {
  //       return this._authService
  //         .signup(
  //           payload.email,
  //           payload.password,
  //           payload.firstName,
  //           payload.lastName,
  //           payload.username,
  //           payload.zipcode,
  //           payload.dob
  //         )
  //         .pipe(
  //           map((response) => {
  //             // this._authService.setJwtToken(response.jwtToken);
  //             // console.log(response);
  //             const message = response.message;
  //             return AuthActions.signupSuccess({ message }); // navigate to page directions
  //           }),
  //           catchError((error) => {
  //             return of(AuthActions.signupFailure({ errMsg: error.message }));
  //           })
  //         );
  //     })
  //   )
  // );

  // signupSuccess$ = createEffect(
  //   (): any =>
  //     this._actions$.pipe(
  //       ofType(AuthActions.SIGNUP_SUCCESS),
  //       tap(() => {
  //         this._router.navigateByUrl(PATHS.SIGNUP_SUCCESS);
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // logoutIdleUser$ = createEffect(() =>
  //   this._actions$.pipe(
  //     ofType(UserActions.idleTimeout),
  //     map(() => AuthActions.logout())
  //   )
  // );
  // Add effect for forgot password flow.
}
